import { render, staticRenderFns } from "./CreateCategory.vue?vue&type=template&id=72ecdfc2&scoped=true&"
import script from "./CreateCategory.vue?vue&type=script&lang=js&"
export * from "./CreateCategory.vue?vue&type=script&lang=js&"
import style0 from "./CreateCategory.vue?vue&type=style&index=0&id=72ecdfc2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72ecdfc2",
  null
  
)

export default component.exports