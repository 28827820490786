<template>
  <v-container>
    <h2 class="pt-5">Construction Category</h2>

    <v-row>
      <v-col md="4">
        <v-container>
          <base-material-card
            color="blue-grey darken-1"
            class="custom-material-card"
          >
            <template v-slot:heading>
              <div class="d-flex justify-space-between align-center">
                <div class="display-2 font-weight-light text-center">
                  Category
                </div>
                <v-btn color="green" @click="openCreate" depressed>
                  <v-icon class="mr-2">mdi-folder-multiple-plus</v-icon>
                  Add New</v-btn
                >
              </div>
            </template>

            <v-list>
              <v-list-item-group>
                <v-list-item
                  v-for="(item, i) in categoryList"
                  :key="item.id"
                  @click="selectId(item.id)"
                  :class="item.id==selectedCategoryId && 'selected-category'"
                >
                  <v-list-item-icon>
                    <!-- <v-icon v-text="item.icon"></v-icon> -->
                    <v-img
                      :src="fileUrl + '/'+item.image"
                      height="60"
                      width="60"
                    ></v-img>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.name"
                      class="category-list-title"
                    ></v-list-item-title>

                    <div class="actions">
                      <v-btn
                        small
                        depressed
                        color="blue"
                        @click="openEdit(item)"
                        text
                      >
                        <v-icon color="blue" class="mr-2"
                          >mdi-pencil-box-outline</v-icon
                        >
                        Edit
                      </v-btn>
                      <v-btn
                        small
                        depressed
                        text
                        color="red"
                        @click="openDelete(item)"
                      >
                        <v-icon color="red" class="mr-2"
                          >mdi-trash-can-outline</v-icon
                        >
                        Delete
                      </v-btn>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </base-material-card>
        </v-container>
      </v-col>
      <v-col md="8">
        <CreateSubCategory
          v-if="selectedCategoryId"
          disabled
          :selectedCategoryId="selectedCategoryId"
          :key="selectedCategoryId"
        />
      </v-col>
    </v-row>

    <v-dialog v-model="dialogCreate" max-width="600px" @click:outside="closeCreate">
      <v-toolbar color="primary" dark>
        Add Category
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
          color="white"
        ></v-progress-linear>
      </v-toolbar>
      <v-card :disabled="loading">
        <v-card-text>
          <v-form v-model="valid" ref="form">
          <v-row>
            <v-col md="12">
              <v-text-field
                label="Category Title"
                outlined
                dense
                v-model="category.name"
                hide-details="true"
                :rules="requiredRule('Category Title')"
              ></v-text-field>
            </v-col>

            <v-col md="12">
              <v-file-input
                accept="image/*"
                label="File input"
                @change="fileChange"
                :rules="requiredRule('Photo')"
              ></v-file-input>
            </v-col>
          </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeCreate"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="addCategory">Save</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEdit" max-width="600px">
      <v-toolbar color="primary" dark>
        Edit Category
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
          color="white"
        ></v-progress-linear>
      </v-toolbar>
      <v-card :disabled="loading">
        <v-card-text>
          <v-row>
            <v-col md="12">
              <v-text-field
                label="Category Title"
                outlined
                dense
                v-model="selectedItem.name"
              ></v-text-field>
            </v-col>
            <v-col md="12">
              <v-file-input
                accept="image/*"
                label="File input"
                @change="fileChange"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogEdit = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="editCategory">Save</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-text>
          <h4>Are you sure you want to delete this item?</h4>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteCategory">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CreateSubCategory from "./CreateSubCategory.vue";
import AddEditCateogory from "./AddEditCategory.vue";
export default {
  components: {
    CreateSubCategory,
    AddEditCateogory,
  },
  data() {
    return {
      valid:false,
      loading: false,
      mode: "add-category",
      fileUrl: process.env.VUE_APP_FILE_URL,
      file: null,
      selectedItem: {},
      category: {},
      categoryList: [],
      selectedCategoryId: null,
      model: 1,
      dialogCreate: false,
      dialogEdit: false,
      dialogDelete: false,
    };
  },
  methods: {
      requiredRule(field) {
      return [(v) => !!v || `${field} is required`];
    },
    openCreate() {
      this.dialogCreate = true;
    },
    closeCreate(){
      this.dialogCreate = false;
      this.$refs.form.reset();
    },
    openEdit(item) {
      this.dialogEdit = true;
      Object.assign(this.selectedItem, item);
    },
    openDelete(item) {
      this.dialogDelete = true;
      this.selectedItem = item;
    },
    fileChange(file) {
      this.file = file;
    },
    prepareUploadImage() {
      let formData = new FormData();
      formData.append("file", this.file);
      return this.$File.uploadFile(formData);
    },
    async addCategory() {
      if(this.valid){
           this.loading = true;
      try {
        if (this.file) {
          let response = await this.prepareUploadImage();
          let fileName = response.data.data;

          let addCategoryResponse = await this.$Category.createCategory({
            name: this.category.name,
            image: fileName,
          });
          this.dialogCreate = false;
          this.loading = false;
          this.category = {};
          this.file = null;
          this.getCategories();
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
      }else{
          this.$refs.form.validate()
      }
   
    },
    async editCategory() {
      this.loading = true;
      try {
        if (this.file) {
          let response = await this.prepareUploadImage();
          this.selectedItem.fileName = response.data.data;
        }

        let editCategoryResponse = await this.$Category.updateCategory({
          id: this.selectedItem.id,
          name: this.selectedItem.name,
          image: this.selectedItem.fileName,
        });
        this.loading = false;
        this.dialogEdit = false;
        this.getCategories();
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    deleteCategory() {
      this.$Category
        .deleteCategory(this.selectedItem.id)
        .then((data) => {
          this.dialogDelete = false;
          this.getCategories();
        })
        .catch((error) => {
          console.log(error);
          this.dialogDelete = false;
        });
    },
    getCategories() {
      this.$Category
        .getCategory()
        .then((data) => {
          this.categoryList = data.data.data;
          // this.selectedCategoryId = data.data.data[0].id;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    selectId(id) {
      this.mode = "edit-category";
      this.selectedCategoryId = id;
    },
  },

  mounted() {
    this.getCategories();
  },
};
</script>

<style scoped>
.category-list-title {
  font-weight: 500;
  font-size: 14px;
}

.actions {
  margin-top: 10px;
}

.selected-category{
  background: #ccc;
}
</style>