<template>
  <base-material-card color="blue-grey darken-1" class="custom-material-card">
    <template v-slot:heading>
      <div class="d-flex justify-space-between align-center">
        <div class="display-2 font-weight-light text-center">Sub Category</div>
        <v-btn color="green" @click="openCreate" depressed>
          <v-icon class="mr-2">mdi-folder-multiple-plus-outline</v-icon>
          Add Sub New</v-btn
        >
      </div>
    </template>

    <div class="pt-5 text-center" v-if="categoryDetails.length==0">
      No Sub Categories Data. Make One
    </div>
    <v-simple-table v-else>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Image</th>
            <th class="text-left">Name</th>
            <th class="text-left">Price</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in categoryDetails" :key="index">
            <td>
              <v-img
                :src="fileUrl + '/' + item.image"
                height="60"
                width="60"
              ></v-img>
            </td>
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.price }} / {{item.unit}}
            </td>

            <td>
              <v-icon color="blue" @click="openEdit(item)"
                >mdi-pencil-box-outline</v-icon
              >
              <v-icon color="red" @click="openDelete(item)"
                >mdi-trash-can-outline</v-icon
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog v-model="dialogCreate" max-width="800px" @click:outside="closeCreate">
      <v-toolbar color="primary" dark>
        Add Sub Category
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
          color="white"
        ></v-progress-linear>
      </v-toolbar>
      <v-card :disabled="loading">
        <v-card-text>
          <v-form ref="createForm" v-model="createValid">
            <v-row>
            <v-col md="6">
              <v-text-field
                label="Sub Category Title"
                outlined
                dense
                v-model="category.name"
                :rules="requiredRule('Title')"
              ></v-text-field>
            </v-col>
                  <v-col md="3">
              <v-text-field
              type="number"
                label="Price"
                outlined
                dense
                v-model="category.price"
                :rules="requiredRule('Price')"
              ></v-text-field>
            </v-col>
              <v-col md="3">
                <v-combobox 
                label="Units" 
                :items="units" v-model="category.unit" 
                outlined dense
                :rules="requiredRule('Unit')"
                ></v-combobox>
        </v-col>
            <v-col md="12">
              <v-textarea
                label="Description"
                outlined
                dense
                v-model="category.description"
                :rules="requiredRule('Description')"
              ></v-textarea>
            </v-col>
            <v-file-input
              accept="image/*"
              label="File input"
              @change="fileChange"
              :rules="requiredRule('Photo')"
            ></v-file-input>
          </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeCreate">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="addCategory">Save</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEdit" max-width="800px" >
      <v-toolbar color="primary" dark>
        Edit Sub Category
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
          color="white"
        ></v-progress-linear>
      </v-toolbar>
      <v-card :disabled="loading">
        <v-card-text>
          <v-form v-model="editValid" ref="editForm">
          <v-row>
            <v-col md="6">
              <v-text-field
                label="Sub Category Title"
                outlined
                dense
                v-model="selectedItem.name"
                :rules="requiredRule('Title')"
              ></v-text-field>
            </v-col>
            <v-col md="3">
              <v-text-field
              type="number"
                label="Price"
                outlined
                dense
                v-model="selectedItem.price"
                :rules="requiredRule('Price')"
              ></v-text-field>
            </v-col>
              <v-col md="3">
                <v-combobox 
                label="Units" 
                :items="units" v-model="selectedItem.unit" 
                outlined dense
                :rules="requiredRule('Unit')"
                ></v-combobox>
        </v-col>
            <v-col md="12">
              <v-textarea
                label="Description"
                outlined
                dense
                v-model="selectedItem.description"
                :rules="requiredRule('Description')"
              ></v-textarea>
            </v-col>
            <v-file-input
              accept="image/*"
              label="File input"
              @change="fileChange"
              ></v-file-input>
          </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogEdit = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="editCategory">Save</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-text>
          <h4>Are you sure you want to delete this item?</h4>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteCategory">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </base-material-card>
</template>

<script>
export default {
  props: {
    selectedCategoryId: {
      required: false,
    },
  },
  data() {
    return {
      units: ['sq.ft.', 'piece','seater'],
      fileUrl: process.env.VUE_APP_FILE_URL,
      createValid: false,
      editValid: false,
      loading: false,
      headers: [
        {
          text: "Name",
          value: "name",
        },
        { text: "Description", value: "description" },
        { text: "Image", value: "fat" },
        {
          text: "",
        },
      ],
      category: {},
      categoryDetails: {},
      selectedItem: {},
      file: null,
      dialogCreate: false,
      dialogEdit: false,
      dialogDelete: false,
    };
  },

  methods: {
    openCreate(){
      this.dialogCreate = true;
      // this.$refs.createForm.reset();
    },
    closeCreate() {
      this.dialogCreate = false;
      this.$refs.createForm.reset();
    },
    fileChange(file) {
      this.file = file;
    },
    openEdit(item) {
      this.dialogEdit = true;
      Object.assign(this.selectedItem, item);
    },
    openDelete(item) {
      this.dialogDelete = true;
      this.selectedItem = item;
    },
    prepareUploadImage() {
      let formData = new FormData();
      formData.append("file", this.file);
      return this.$File.uploadFile(formData);
    },
    async addCategory() {
      if(this.createValid){
        this.loading = true;
      try {
        
        if (this.file) {
          let response = await this.prepareUploadImage();
          let fileName = response.data.data;

          let addCategoryResponse = await this.$Category.createSubCategory({
            name: this.category.name,
            description: this.category.description,
            categoryId: this.selectedCategoryId,
            price: this.category.price,
            unit: this.category.unit,
            image: fileName,
          });
          this.closeCreate();
          this.getCategoryById();
          this.loading = false;
          this.category = {};
          this.file = null;
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
      }else{
        this.$refs.createForm.validate()
      }
   
    },
    async editCategory() {
      if(this.editValid){
          this.loading = true;
      try {
        if (this.file) {
          let response = await this.prepareUploadImage();
          this.categoryDetails.fileName = response.data.data;
        }

        let editCategoryResponse = await this.$Category.updateSubCategory({
          id: this.selectedItem.id,
          categoryId: this.selectedCategoryId,
          name: this.selectedItem.name,
          description: this.selectedItem.description,
          price: this.selectedItem.price,
          unit: this.selectedItem.unit,
          image: this.categoryDetails.fileName,
        });
        this.loading = false;
        this.dialogEdit = false;
        this.selectedItem = {};
        this.file = null;
        this.getCategoryById();
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
      }else{
        this.$refs.editForm.validate()
      }
    
    },
    deleteCategory() {
      this.$Category
        .deleteSubCategory(this.selectedItem.id)
        .then((data) => {
          this.dialogDelete = false;
          this.getCategoryById();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCategoryById() {
      this.$Category
        .getSubCategoriesByCategory(this.selectedCategoryId)
        .then((data) => {
          this.categoryDetails = data.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getCategoryById();
  },
};
</script>