<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col md="12">
          <v-text-field
            label="Category Name"
            outlined
            dense
            v-model="categoryDetails.name"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="8">
          <v-file-input
            accept="image/*"
            label="File input"
            @change="fileChange"
          ></v-file-input>
        </v-col>
        <v-col md="4">
          <v-btn color="primary" @click="addCategory" v-if="mode=='add-category'">Add</v-btn>
          <v-btn color="primary" @click="editCategory" v-if="mode=='edit-category'">Edit</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
     props:{
        selectedCategoryId:{
            required: false
        },
        mode:{

        }
    },
  data() {
    return {
      categoryDetails:{},
      file: null,
    };
  },
  methods: {
      getCategoryById(){
             this.$Category.getCategoryById(this.selectedCategoryId).then(data=>{
            this.categoryDetails = data.data.data;
          }).catch(error=>{
            console.log(error);
          })
        },
    fileChange(file) {
      this.file = file;
    },
    async addCategory() {
      try {
        if(this.file){
            let response = await this.prepareUploadImage();
            let fileName = response.data.data;

            let addCategoryResponse = await this.$Category.createCategory({
          name: this.categoryDetails.name,
          image: fileName,
        });
        }
        } catch (error) {
        console.log(error);
      }
    },
    async editCategory(){
        
        try{
             if(this.file){
            let response = await this.prepareUploadImage();
            this.categoryDetails.fileName = response.data.data;
             }

            let editCategoryResponse = await this.$Category.updateCategory({
            id: this.categoryDetails.id,
          name: this.categoryDetails.name,
          image: this.categoryDetails.fileName,
        });
        
        }catch(error){
            console.log(error);
        }
    },
    prepareUploadImage() {
      let formData = new FormData();
      formData.append("file", this.file);
      return this.$File.uploadFile(formData);
    },
  },
  mounted(){
    if(this.mode=='edit-category'){
        this.getCategoryById()
    }
  }
};
</script>

<style scoped>
</style>